import { useLazyQuery, useMutation } from "@apollo/client";
import FormField from "components/FormField";
import UserPasswordChangeModal from "components/User/Modals/UserPasswordChangeModal";
import { EUserTypes } from "components/User/userTypes";
import { Formik } from "formik";
import { GET_EMAIL_VALIDATION } from "graphql/GET_EMAIL_VALIDATION";
import { GET_PHONE_VALIDATION } from "graphql/GET_PHONE_VALIDATION";
import { UPDATE_STUDENT_MUTATION } from "graphql/UPDATE_STUDENT_MUTATION";
import { UPDATE_USER_MUTATION } from "graphql/UPDATE_USER_MUTATION";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { Button, Card, Form, Icon } from "tabler-react";
import * as Yup from "yup";

const updateContactInfoValidationSchema = Yup.object().shape({
  phone_number: Yup.string().required("This field is required."),
  email: Yup.string().required("This field is required."),
});

const UserContactInfo = ({ user, userClone }) => {
  const rootStore = useRootStore();
  const { currentUser } = rootStore;
  const { isAdmin } = useGetCurrentUserType();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION, {
    onCompleted: () => toast.success("Profile Updated."),
  });

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => toast.success("Profile Updated."),
  });

  const [validateEmail, { data: emailData }] = useLazyQuery(
    GET_EMAIL_VALIDATION,
    { fetchPolicy: "network-only" },
  );
  const [validatePhone, { data: phoneData }] = useLazyQuery(
    GET_PHONE_VALIDATION,
    { fetchPolicy: "network-only" },
  );

  const HandleValidateEmail = async (email) => {
    validateEmail({
      variables: { email },
    });
  };

  const HandleValidatePhone = async (phone) => {
    validatePhone({
      variables: { phone },
    });
  };

  const mstChangePhone = (e) => {
    e.preventDefault();
    const target = e.target;

    const phone = target.value.split(" ");
    const countryCode = phone.shift().replace(/\D/g, "");

    userClone.set("phone_number", phone.join("").replace(/\D/g, ""));
    userClone.set("phone_country_code", Number(countryCode));
  };

  const mstHandleChange = (e) => {
    userClone.set(e.target.name, e.target.value);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          id: userClone.id,
          phone_number: userClone.phone_number,
          phone_country_code: userClone.phone_country_code,
          email: userClone.email,
          email_verified: userClone.email_verified,
        }}
        validationSchema={updateContactInfoValidationSchema}
        onSubmit={async (
          values,
          { setSubmitting, resetForm, setFieldError },
        ) => {
          let errorFound = false;

          if (emailData?.validateEmail) {
            setFieldError("email", "Email already exists. Contact SKYCRU.");
            errorFound = true;
          }

          if (phoneData?.validatePhone) {
            setFieldError(
              "phone_number",
              "Phone number already exists. Contact SKYCRU.",
            );
            errorFound = true;
          }

          if (errorFound) {
            setSubmitting(false);
            return false;
          }

          if (user.type === EUserTypes.student) {
            await updateStudent({
              variables: {
                student: {
                  ...values,
                },
              },
            });
          } else {
            await updateUser({
              variables: {
                user: {
                  ...values,
                  type: user.type,
                },
              },
            });
          }

          applySnapshot(rootStore, {
            ...rootStore,
            currentUser: { ...currentUser, ...values },
          });

          applySnapshot(user, getSnapshot(userClone));
          setSubmitting(false);
          resetForm();
        }}
      >
        {({ values, errors, touched, isSubmitting, handleSubmit }) => (
          <form className="card account-update-form " onSubmit={handleSubmit}>
            <Card.Header>
              <Card.Title>
                <Icon name="lock" className="mr-2 ml-0 text-secondary" />
                Sign-in & Security
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {!isAdmin ? (
                <>
                  <Form.Group label="Phone">
                    <PhoneInput
                      inputProps={{
                        className: "form-control w-100",
                        name: "phone_number",
                        required: true,
                        onChange: (e) => mstChangePhone(e),
                        onBlur: async (e) => {
                          e.preventDefault();
                          await HandleValidatePhone(
                            e.target.value
                              .split(" ")
                              .join("")
                              .replace(/\D/g, ""),
                          );
                        },
                      }}
                      value={`${values.phone_country_code}${values.phone_number}`}
                    />
                    <span className="field-error text-danger">
                      {errors.phone_number &&
                        touched.phone_number &&
                        errors.phone_number}
                    </span>
                  </Form.Group>
                  <Form.Group label="Email">
                    <FormField
                      appendleft={
                        <Button icon={"mail"} color={"secondary"} disabled />
                      }
                      type="email"
                      name="email"
                      onChange={mstHandleChange}
                      value={values.email}
                      onBlur={async (e) => {
                        await HandleValidateEmail(e.target.value);
                      }}
                    />
                  </Form.Group>
                </>
              ) : (
                <span>username: {values.email}</span>
              )}
            </Card.Body>
            {!isAdmin && (
              <Card.Footer>
                <Button
                  className="float-left text-muted"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  pill
                  color="white"
                  size="sm"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                  }}
                >
                  PASSWORD
                </Button>
                <Button.List align="right">
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    pill
                    color="gray-dark"
                    size="sm"
                    type="submit"
                  >
                    UPDATE
                  </Button>
                </Button.List>
              </Card.Footer>
            )}
          </form>
        )}
      </Formik>
      <UserPasswordChangeModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
      />
    </>
  );
};

export default observer(UserContactInfo);
