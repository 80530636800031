import { useQuery } from '@apollo/client';
import CalendarLink from 'components/Calendar/CalendarLink';
import { GET_EVENTS_AS_ORGANIZER } from 'graphql/GET_EVENTS_AS_ORGANIZER';
import { GET_EVENTS_AS_ORGANIZER as GET_EVENTS_AS_ORGANIZER_TYPE } from 'graphql/types/GET_EVENTS_AS_ORGANIZER';
import { useRootStore } from 'hooks';
import moment from 'moment';
import React, { useMemo } from 'react';
import Moment from 'react-moment';
import { Card, Grid, Icon, Text } from 'tabler-react';

const MyEventsCard = () => {
  const { currentUser } = useRootStore();

  const { data: eventsData } = useQuery<GET_EVENTS_AS_ORGANIZER_TYPE>(
    GET_EVENTS_AS_ORGANIZER,
    {
      variables: {
        coach_id: currentUser.id
      }
    }
  );

  const eventRows = useMemo(
    () => eventsData?.getEventsAsOrganizer.map((item) => item) ?? [],
    [eventsData?.getEventsAsOrganizer]
  );

  if (!eventRows.length) {
    return null;
  }

  return (
    <Grid.Col sm={12} lg={4} className="pl-0">
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name="calendar" className="mr-2 ml-0 text-primary" />
            My Events
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {eventRows
            .filter((event) => moment(event.end) > moment())
            .reverse()
            .map((event) => (
              <Grid.Row key={event.id} className={'mb-4'}>
                <Grid.Col>
                  <CalendarLink
                    eventName={event.camp_name}
                    eventId={Number(event.id)}
                  />
                  <Text.Small className="d-block text-muted">
                    <Moment format="MMM D">{event.start}</Moment> -{' '}
                    <strong>{event.location}</strong>
                  </Text.Small>
                </Grid.Col>
              </Grid.Row>
            ))}
        </Card.Body>
      </Card>
    </Grid.Col>
  );
};

export default MyEventsCard;
