import { useLazyQuery, useMutation } from "@apollo/client";
import FormField from "components/FormField";
import { UserStoreContext } from "contexts/userStoreContext";
import { Formik, FormikValues } from "formik";
import { ADD_STUDENT } from "graphql/ADD_STUDENT";
import { GET_EMAIL_VALIDATION } from "graphql/GET_EMAIL_VALIDATION";
import { GET_PHONE_VALIDATION } from "graphql/GET_PHONE_VALIDATION";
import React, { useContext } from "react";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { Button, Card, Form, Icon, Text } from "tabler-react";
import * as Yup from "yup";

const addStudentValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required."),
  last_name: Yup.string().required("Last name is required."),
  email: Yup.string().email().required("Email is required."),
  phone_number: Yup.string().required(
    "Phone is required, including country code.",
  ),
});

interface ISignupFormProps {
  setStep: any;
}

const SignUpForm = ({ setStep }: ISignupFormProps) => {
  const userStore = useContext(UserStoreContext);

  const [addStudent] = useMutation(ADD_STUDENT);

  const [validateEmail, { data: emailData, loading: emailLoading }] =
    useLazyQuery(GET_EMAIL_VALIDATION, { fetchPolicy: "network-only" });

  const [validatePhone, { data: phoneData, loading: phoneLoading }] =
    useLazyQuery(GET_PHONE_VALIDATION, { fetchPolicy: "network-only" });

  const HandleValidateEmail = async (email) => {
    validateEmail({
      variables: { email },
    });
  };

  const HandleValidatePhone = async (phone) => {
    validatePhone({
      variables: { phone },
    });
  };

  const renderForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  }: FormikValues) => {
    return (
      <form
        className="card Create-Account"
        method="POST"
        onSubmit={handleSubmit}
      >
        <Card.Body className="p-6">
          <FormField
            type="text"
            label="First Name*"
            placeholder="First name"
            name="first_name"
            onChange={handleChange}
            value={values.first_name}
          />
          <FormField
            type="text"
            label="Last Name*"
            placeholder="Last name"
            name="last_name"
            onChange={handleChange}
            value={values.last_name}
          />
          <div className={"form-group"}>
            <label className="form-label">Phone*</label>
            <PhoneInput
              inputProps={{
                className: "form-control w-100",
                name: "phone_number",
                required: true,

                onChange: (e) => {
                  e.preventDefault();
                  const target = e.target;
                  const phone = target.value.split(" ");
                  const countryCode = phone.shift().replace(/\D/g, "");
                  const phoneNumber = phone.join("").replace(/\D/g, "");
                  setFieldValue("phone_number", phoneNumber);
                  setFieldValue("phone_country_code", Number(countryCode));
                },
                onBlur: async (e) => {
                  e.preventDefault();
                  await HandleValidatePhone(
                    e.target.value.split(" ").join("").replace(/\D/g, ""),
                  );
                },
              }}
              value={`${values.phone_country_code}${values.phone_number}`}
            />
            <span className="field-error text-danger">
              {errors.phone_number &&
                touched.phone_number &&
                errors.phone_number}
            </span>
          </div>
          <FormField
            appendleft={
              <Button icon={"mail"} color={"secondary text-muted"} disabled />
            }
            type="email"
            label="Email*"
            placeholder="Email"
            name="email"
            onChange={handleChange}
            onBlur={async (e) => {
              await HandleValidateEmail(e.target.value);
            }}
            value={values.email}
          />
          <Form.Group>
            {values.terms ? (
              <Icon
                name={"check-square"}
                onClick={() => setFieldValue("terms", false)}
              />
            ) : (
              <Icon
                name={"square"}
                onClick={() => setFieldValue("terms", true)}
              />
            )}
            <span className="ml-2">
              {" "}
              I Agree, {" "}
              <a
                href="https://www.skycru.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy and Terms
              </a>
            </span>
            <br />
            <span className="field-error text-danger">
              {errors.terms && touched.terms && errors.terms}
            </span>
          </Form.Group>

          <Form.Footer className="mb-6">
            <button
              type="submit"
              className={`btn btn-primary btn-block ${
                isSubmitting && "btn-loading"
              }`}
              disabled={isSubmitting || emailLoading || phoneLoading}
            >
              CREATE ACCOUNT
            </button>
          </Form.Footer>
        </Card.Body>
      </form>
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          phone_country_code: "1",
          terms: false,
        }}
        validationSchema={addStudentValidationSchema}
        onSubmit={async (
          values,
          { setSubmitting, resetForm, setFieldError },
        ) => {
          const { terms, ...cloneStudent } = values;
          let errorFound = false;

          if (emailData?.validateEmail) {
            setFieldError("email", "Email already exists. Contact SKYCRU.");
            errorFound = true;
          }

          if (phoneData?.validatePhone) {
            setFieldError(
              "phone_number",
              "Phone number already exists. Contact SKYCRU.",
            );
            errorFound = true;
          }

          if (!terms) {
            setFieldError("terms", "Please read and accept terms.");
            errorFound = true;
          }

          if (errorFound) {
            setSubmitting(false);
            return false;
          }
          const newStudent = await addStudent({
            variables: {
              student: {
                ...cloneStudent,
              },
            },
          });

          if (newStudent?.data.addStudent.id) {
            userStore.loadUser(newStudent?.data.addStudent);

            toast.success("Please check your email to activate account!");
            setStep(3);
          }
          setSubmitting(false);
          resetForm();
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
      <Text className="text-center text-muted">
        Already have an account? <a href="/login">LOGIN</a>
      </Text>
    </>
  );
};

export default SignUpForm;
