import React, { useMemo } from "react";
import { Button, Card } from "tabler-react";
import { useGetRegistrationGroups } from "../../modules/group/hooks/useGetRegistrationGroups";
import RegistrationGroupFormModal from "./Modals/RegistrationGroupFormModal";
import RegistrationGroupItem from "./RegistrationGroupItem";
import useGetCurrentUserType from "../../modules/common/hooks/useGetCurrentUserType";

interface RegistrationGroupsProps {
  registrationId?: number;
  disciplineId?: number;
  teamId?: number;
  campId?: number;
  isOrg?: boolean;
  eventTypeId?: string;
  roleId?: number;
}

const groupManagerRoles = [
  20, // Delegation Member
  21, // Manager
  24, // head of delegation
  26, // NAC
];

const RegistrationGroups = ({
  registrationId,
  disciplineId,
  roleId,
  teamId,
  campId,
  isOrg,
  eventTypeId,
}: RegistrationGroupsProps) => {
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType();
  const [isOpen, setIsOpen] = React.useState(false);
  const [registrationGroup, setRegistrationGroup] = React.useState(null);
  const { data: groupsData, loading: groupsLoading } = useGetRegistrationGroups(
    {
      registrationId,
      status: "Active",
    },
  );
  const isGroupManager = groupManagerRoles.includes(roleId);

  const groupClickHandle = (group) => {
    if (!isGroupManager) return;
    setRegistrationGroup(group);
    setIsOpen(true);
  };

  const groups = useMemo(() => {
    if (!groupsLoading && groupsData) {
      return groupsData;
    }

    return [];
  }, [groupsData, groupsLoading]);

  if (!isOrg && eventTypeId !== "2") return null;

  return (
    <Card>
      {isGroupManager &&
      <Card.Header>
        <Card.Title as="h5">Groups</Card.Title>
        <Card.Options>
          {(isAdmin || isCoachSuperAdmin || isOrg || isGroupManager) && (
            <>
              <Button
                outline
                icon={"plus"}
                color={"primary"}
                size={"sm"}
                onClick={() =>
                  groupClickHandle({
                    team_id: teamId,
                    user_registration_id: registrationId,
                    discipline_id: disciplineId,
                  })
                }
              />
            </>
          )}
        </Card.Options>
      </Card.Header>
      }
      <Card.Body>
        {groups.map((group) => (
          <RegistrationGroupItem
            group={group}
            key={group.id}
            campId={campId}
            isGroupManager={isGroupManager}
            groupClickHandle={groupClickHandle}
          />
        ))}
      </Card.Body>
      <RegistrationGroupFormModal
        group={registrationGroup}
        campId={campId}
        toggle={() => setIsOpen(false)}
        isOpen={isOpen}
      />
    </Card>
  );
};

export default RegistrationGroups;
