import { useMutation, useQuery } from "@apollo/client";
import CalendarLink from "components/Calendar/CalendarLink";
import { Loading } from "components/Loading";
import PaymentsSidebar from "components/Payments/PaymentsSidebar";
import RegistrationActivityListModal from "components/Registration/Modals/RegistrationActivityListModal";
import RegistrationStudentDetails from "components/Registration/RegistrationStudentDetails";
import ConfirmedSlotsModal from "components/Students/ConfirmedSlotsModal";
import StudentNotesList from "components/Students/StudentNotesList";
import StudentRecentCamps from "components/Students/StudentRecentCamps";
import UserRegistrationOption from "components/User/Forms/UserRegistrationOption";
import UserRegistrationQuestionItem from "components/User/Forms/UserRegistrationQuestionItem";
import UpdateUserRegistrationOptionModal from "components/User/Modals/UpdateUserRegistrationOptionModal";
import PaymentHistory from "components/User/PaymentHistory";
import UserCard from "components/User/Profile/UserCard";
import { GET_CAMP_REGISTRATION_REQUIREMENTS } from "graphql/GET_CAMP_REGISTRATION_REQUIREMENTS";
import { GET_REGISTRATION_QUESTIONS } from "graphql/GET_REGISTRATION_QUESTIONS";
import { GET_USER_REGISTRATIONS_BY_IDS } from "graphql/GET_USER_REGISTRATIONS_BY_IDS";
import { GET_CAMP_REGISTRATION_REQUIREMENTS as GET_CAMP_REGISTRATION_REQUIREMENTS_TYPE } from "graphql/types/GET_CAMP_REGISTRATION_REQUIREMENTS";
import { GET_REGISTRATION_QUESTIONS as GET_REGISTRATION_QUESTIONS_TYPE } from "graphql/types/GET_REGISTRATION_QUESTIONS";
import { GET_USER_REGISTRATIONS_BY_IDS as GET_USER_REGISTRATIONS_BY_IDS_TYPE } from "graphql/types/GET_USER_REGISTRATIONS_BY_IDS";
import { UPDATE_USER_REGISTRATION } from "graphql/UPDATE_USER_REGISTRATION";
import { useRootStore } from "hooks";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import CRMMessagesList from "modules/crm-message/components/lists/CRMMessagesList";
import CampRequirements from "modules/registration/requirements/components/grids/CampRequirements";
import QRCodeUserRegistration from "modules/user/registrations/components/buttons/QRCodeUserRegistration";
import UserRegistrationStatusDropdown from "modules/user/registrations/components/dropdowns/UserRegistrationStatusDropdown";

import moment from "moment";
import React, { useMemo, useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router";
import { Button, Card, Form, Grid, Text } from "tabler-react";
import { formatMoney } from "utils/numberFormat";
import { Types } from "../../types/graphql";
import RegistrationGroups from "../Registration/RegistrationGroups";
import StudentTeamProgressionModal from "../Students/Modal/studentTeamProgressionModal";

enum EModalTypes {
  manualPaymentModal = "manualPaymentModal",
  slotsConfirmedModal = "slotsConfirmedModal",
  registrationOptionModal = "registrationOptionModal",
}

interface IModalStates {
  slotsConfirmedModal: boolean;
  registrationOptionModal: boolean;
}

const modalInitState = {
  manualPaymentModal: false,
  slotsConfirmedModal: false,
  registrationOptionModal: false,
};

const UserRegistration = () => {
  const { userRegId, studentId } = useParams<{
    userRegId: string;
    studentId: string;
  }>();

  const { loading, error, data } = useQuery<GET_USER_REGISTRATIONS_BY_IDS_TYPE>(
    GET_USER_REGISTRATIONS_BY_IDS,
    {
      variables: {
        userRegistrationsId: Number(userRegId),
      },
      fetchPolicy: "network-only",
    },
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    console.log(error);
    return <p>Error: {error.message}</p>;
  }

  return (
    <UserRegistrationInner
      userRegistrationDetails={data.getUserRegistrationsByIds}
      studentId={Number(studentId)}
      userRegId={Number(data.getUserRegistrationsByIds.user_registrations_id)}
    />
  );
};

const UserRegistrationInner = ({
  userRegistrationDetails,
  studentId,
  userRegId,
}) => {
  const { isAdmin, isCoachSuperAdmin, isOrg, isStudent } =
    useGetCurrentUserType();

  const { totalPaid, outstanding } = userRegistrationDetails.paymentDetail;

  const { currentUser } = useRootStore();
  const [isModalOpen, setIsModalOpen] = useState<IModalStates>(modalInitState);
  const [isActivityModalOpen, setIsActivityModalOpen] =
    useState<boolean>(false);

  const [isEditting, setIsEditting] = useState<boolean>();
  const [isEdittingRating, setIsEdittingRating] = useState<boolean>();

  const handleToggleModal = (mType: EModalTypes) =>
    setIsModalOpen({
      ...isModalOpen,
      [mType]: !isModalOpen[mType],
    });

  const [rating, setRating] = useState(userRegistrationDetails.rating);
  const [quantity, setQuantity] = useState(
    userRegistrationDetails.jump_quantity,
  );

  const { data: questionData } = useQuery<GET_REGISTRATION_QUESTIONS_TYPE>(
    GET_REGISTRATION_QUESTIONS,
    {
      variables: {
        filter: {
          userRegistrationId: userRegId,
        },
      },
    },
  );

  const [updateUserRegistration] = useMutation(UPDATE_USER_REGISTRATION, {
    refetchQueries: ["GET_USER_REGISTRATIONS_BY_IDS"],
  });

  const updateJumpQuantity = () => {
    updateUserRegistration({
      variables: {
        userRegistration: {
          id: userRegId.toString(),
          jump_quantity: quantity,
          coach_id: currentUser.id,
          status: userRegistrationDetails.status,
        },
      },
    });
    setIsEditting(false);
  };

  const { data: campRequirementsData } =
    useQuery<GET_CAMP_REGISTRATION_REQUIREMENTS_TYPE>(
      GET_CAMP_REGISTRATION_REQUIREMENTS,
      {
        variables: {
          team_id: userRegistrationDetails?.team_id,
          camp_id: Number(userRegistrationDetails?.camp_id),
        },
      },
    );

  const campRequirements = useMemo(() => {
    return (
      campRequirementsData?.campRegistrationRequirements
        .filter((r) => r.is_exist)
        .map((r) => r.requirement_type_slug) ?? []
    );
  }, [campRequirementsData?.campRegistrationRequirements]);

  const hasPaymentsHistory = userRegistrationDetails.payments.length > 0;

  const updateRating = async () => {
    updateUserRegistration({
      variables: {
        userRegistration: {
          id: userRegId,
          rating: rating,
          coach_id: currentUser.id,
          status: userRegistrationDetails.status,
        },
      },
    });
    setIsEdittingRating(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <Grid.Row>
      <Grid.Col lg={4} sm={12}>
        <RegistrationStudentDetails
          campRequirements={campRequirements}
          userRegistrationDetails={userRegistrationDetails}
        />

        {isStudent && (
          <UserCard
            profileAvatar={userRegistrationDetails?.profile_avatar}
            profilePicture={userRegistrationDetails?.profile_picture}
          />
        )}
        {/* Display QR Code only to student, and only after event has started, and before it has ended */}
        {isStudent &&
          moment(userRegistrationDetails?.start).isAfter() &&
          moment(userRegistrationDetails?.end).isBefore() && (
            <QRCodeUserRegistration
              userRegistration={userRegistrationDetails}
            />
          )}
        {(isAdmin || isCoachSuperAdmin) && (
          <QRCodeUserRegistration userRegistration={userRegistrationDetails} />
        )}
        {userRegistrationDetails && (
          <PaymentsSidebar userRegistrationDetails={userRegistrationDetails} />
        )}

        {!isStudent && !isOrg && (
          <StudentRecentCamps
            studentId={Number(studentId)}
            currentCampId={userRegistrationDetails.camp_id}
          />
        )}

        <RegistrationGroups
          campId={userRegistrationDetails.camp_id}
          registrationId={userRegId}
          teamId={userRegistrationDetails.team_id}
          disciplineId={userRegistrationDetails.disciplineId}
          isOrg={isOrg}
          roleId={userRegistrationDetails.roleId}
          eventTypeId={
            userRegistrationDetails ? userRegistrationDetails.eventTypeId : null
          }
        />
      </Grid.Col>
      <Grid.Col lg={8} sm={12}>
        <Card>
          <Card.Header>
            {!isStudent && (
              <Button
                icon="activity"
                className={"float-left mr-2 text-muted"}
                size="sm"
                onClick={() => setIsActivityModalOpen(true)}
                color="white"
              >
                <span className="d-none d-lg-inline">Activity</span>
              </Button>
            )}
            {userRegistrationDetails?.status === "Confirmed" &&
              ((!isStudent && userRegistrationDetails?.campScoreCards) ||
                (isStudent && userRegistrationDetails.userScoreCard)) && (
                <>
                  <Button
                    icon="file-text"
                    className={"float-left mr-2 text-muted"}
                    size="sm"
                    onClick={toggle}
                    color="white"
                  >
                    <span className="d-none d-lg-inline">Score Card</span>
                  </Button>
                  <StudentTeamProgressionModal
                    studentId={studentId}
                    teamId={userRegistrationDetails?.team_id}
                    isOpen={isOpen}
                    toggle={toggle}
                    userRegistrationDetails={userRegistrationDetails}
                  />
                </>
              )}
            {!isStudent && isEdittingRating ? (
              <input
                autoFocus
                className={"float-right mr-2"}
                min={0}
                max={10}
                type="number"
                value={rating}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value > 10) {
                    setRating(10);
                  } else if (value < 0) {
                    setRating(0);
                  } else {
                    setRating(value);
                  }
                }}
                onBlur={async () => {
                  updateRating();
                }}
                onKeyUp={(event) => {
                  switch (event.keyCode) {
                    case 13:
                      updateRating();
                      break;
                    case 27:
                      setIsEdittingRating(false);
                      break;
                  }
                }}
              />
            ) : (
              !isStudent && (
                <Button
                  icon="star"
                  size="sm"
                  color="white"
                  className={rating > 0 ? "text-info" : "text-secondary"}
                  onClick={() => setIsEdittingRating(true)}
                  disabled={
                    moment().diff(
                      moment(userRegistrationDetails?.end),
                      "days",
                    ) > 1
                  }
                >
                  {rating > 0 && (
                    <span className="ml-1">Event Rating: {rating}</span>
                  )}
                </Button>
              )
            )}
            <Card.Options>
              {/* depreciated, dont delete
                <UserRegistrationLogHistoryButton />
              */}

              {/*EDIT STATUS HERE*/}
              {moment().diff(moment(userRegistrationDetails?.end), "days") >
              1 ? (
                <Button
                  color={'white'}
                //  need to add icon and color  
                //  icon={currentStatus?.icon}
                //  className={currentStatus?.color && 'text-' + currentStatus.color}
                  disabled
                >
                  {userRegistrationDetails?.status}
                </Button>
              ) : (
                <>
                  {userRegistrationDetails && (
                    <UserRegistrationStatusDropdown
                      campId={userRegistrationDetails?.camp_id}
                      registrationStart={userRegistrationDetails?.start}
                      status={userRegistrationDetails?.status}
                      teamId={userRegistrationDetails?.team_id}
                      studentId={userRegistrationDetails?.student_id}
                      userRegId={userRegId}
                    />
                  )}
                </>
              )}
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Grid.Row className="mb-5">
              <Grid.Col>
                <Text>
                  <strong>
                    <CalendarLink
                      eventName={userRegistrationDetails?.camp_name}
                      eventId={Number(userRegistrationDetails?.camp_id)}
                    />
                  </strong>{" "}
                  // {userRegistrationDetails?.team_name}
                </Text>
                <p className="mb-0">
                  <Moment format="MMMM DD, YYYY">
                    {userRegistrationDetails?.start}
                  </Moment>{" "}
                  -{" "}
                  <Moment format="MMMM DD, YYYY">
                    {userRegistrationDetails?.end}
                  </Moment>
                </p>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className="mb-3">
                {userRegistrationDetails && (
                  <CampRequirements
                    userRegistrationDetails={userRegistrationDetails}
                  />
                )}
              </Grid.Col>
            </Grid.Row>
            {userRegistrationDetails?.default_jump_price > 0 && (
              <Grid.Row>
                <Grid.Col>
                  <Text>Jump Tickets</Text>
                  <Text.Small className="d-block text-muted">
                    {formatMoney(userRegistrationDetails?.default_jump_price)}{" "}
                    per jump
                  </Text.Small>
                </Grid.Col>
                <Grid.Col>
                  {isEditting ? (
                    <Form.Input
                      autoFocus
                      placeholder="0"
                      type="number"
                      value={quantity}
                      onChange={(e) => setQuantity(Number(e.target.value))}
                      onBlur={updateJumpQuantity}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          updateJumpQuantity();
                        }
                      }}
                    />
                  ) : (
                    <Button
                      color="secondary"
                      onClick={() => {
                        if (true || isStudent) setIsEditting(true);
                      }}
                    >
                      {userRegistrationDetails?.jump_quantity}
                    </Button>
                  )}
                </Grid.Col>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Col>
                {userRegistrationDetails?.user_registration_options.map(
                  (option) => (
                    <UserRegistrationOption
                      handleToggleModal={() =>
                        handleToggleModal(EModalTypes.registrationOptionModal)
                      }
                      registrationOption={option}
                      key={option.id}
                      registrationId={userRegId}
                    />
                  ),
                )}
                {isCoachSuperAdmin &&
                  userRegistrationDetails?.user_registration_options.length <=
                    0 &&
                    !isStudent && (
                      <Button
                        icon="plus"
                        color="secondary"
                        onClick={() => {
                          if (isStudent)
                            handleToggleModal(
                              EModalTypes.registrationOptionModal
                            )
                        }}
                        className="mt-5"
                      >
                        OPTIONS
                      </Button>
                )}
              </Grid.Col>
              {userRegistrationDetails?.confirmedSlots.length > 0 && (
                <Grid.Col>
                  <div className="h6"></div>
                  <Button
                    color="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      handleToggleModal(EModalTypes.slotsConfirmedModal);
                    }}
                  >
                    {`${
                      userRegistrationDetails?.confirmedSlots.length
                    } slots confirmed / ${moment
                      .utc()
                      .startOf("day")
                      .add({
                        minutes:
                          userRegistrationDetails?.totalTime.total_duration,
                      })
                      .format("H:mm")}`}
                  </Button>
                </Grid.Col>
              )}
            </Grid.Row>
            <UpdateUserRegistrationOptionModal
              isModalOpen={isModalOpen.registrationOptionModal}
              toggleModal={() =>
                handleToggleModal(EModalTypes.registrationOptionModal)
              }
              userData={{
                teamId: userRegistrationDetails?.team_id,
                campId: Number(userRegistrationDetails?.camp_id),
                userRegistrationId: userRegId,
                defaultJumpCount:
                  userRegistrationDetails?.default_jump_count ?? 1,
                userRegistrationOptions:
                  userRegistrationDetails?.user_registration_options,
              }}
            />
          </Card.Body>

          <Card.Footer>
            <Grid.Col className="pl-0">
              <Text className="font-weight-bold">Registered On:</Text>
              <p>
                <Moment format="dddd, MMMM DD, YYYY LT">
                  {userRegistrationDetails?.created_on}
                </Moment>
              </p>
              <p>
                Confirmation Code:{" "}
                <strong>{userRegistrationDetails?.registration_code}</strong>
              </p>
            </Grid.Col>
          </Card.Footer>
        </Card>

        {hasPaymentsHistory && (
          <Card>
            <Card.Body>
              <Grid.Row className="">
                <Grid.Col>
                  <div className="h5">Payments</div>
                  <hr className="border border-primary my-1" />
                  <PaymentHistory
                    paymentsHistory={userRegistrationDetails.payments}
                    remainingBalance={outstanding / 100}
                    totalPaid={totalPaid / 100}
                  />
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </Card>
        )}

        {!isStudent && <CRMMessagesList />}

        <StudentNotesList
          studentId={Number(studentId)}
          registrationId={userRegId}
          teamId={userRegistrationDetails?.team_id}
        />

        {userRegistrationDetails && userRegId && (
          <>
            <ConfirmedSlotsModal
              userRegistrationDetails={userRegistrationDetails}
              isModalOpen={isModalOpen.slotsConfirmedModal}
              toggleModal={() =>
                handleToggleModal(EModalTypes.slotsConfirmedModal)
              }
            />
          </>
        )}

        {questionData?.registrationQuestions.length > 0 &&
          moment(userRegistrationDetails?.end).isAfter() && (
            <Card>
              <Card.Status className="bg-primary" />
              <Card.Header>
                <Card.Title>
                  <i className="fe fe-help-circle text-primary mr-2" />
                  Registration Questionaire
                  {isStudent && (
                    <span className="text-muted ml-2">
                      (click answers to edit)
                    </span>
                  )}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {questionData?.registrationQuestions.map(
                  (question: Types.RegistrationQuestion) => (
                    <UserRegistrationQuestionItem
                      key={question.id}
                      question={question}
                      userRegId={userRegId}
                      studentId={Number(userRegistrationDetails?.student_id)}
                    />
                  ),
                )}
              </Card.Body>
            </Card>
          )}

        <RegistrationActivityListModal
          registrationId={userRegId}
          isOpen={isActivityModalOpen}
          setIsOpen={setIsActivityModalOpen}
        />
      </Grid.Col>
    </Grid.Row>
  );
};
export default UserRegistration;
