import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useContext, useMemo } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Form, Grid } from "tabler-react";
import * as Yup from "yup";
import { UserStoreContext } from "../../../contexts/userStoreContext";
import { ADD_LOAD_USER } from "../../../graphql/ADD_LOAD_USER";
import { ADD_USER_EVENT } from "../../../graphql/ADD_USER_EVENT";
import { GET_ALL_JUMP_TYPES } from "../../../graphql/GET_ALL_JUMP_TYPES";
import { GET_LOAD } from "../../../graphql/GET_LOAD";
import { useRootStore } from "../../../hooks";
import { LoadAssocType } from "../../constants";
import { EUserEventsId } from "../../User/UserEvents";

interface IAddLoadUserProps {
  toggleModal?: () => void;
  loadId: number;
  currentPassengers: string[];
  getLoads?: () => void;
}

const addLoadStudentValidationSchema = Yup.object().shape({
  student_id: Yup.number().required("This field is required."),
  association_type_id: Yup.number().required("This field is required."),
  //  jump_type_id: Yup.number().required('This field is required.')
});

const AddLoadPassenger = ({
  toggleModal,
  loadId,
  currentPassengers,
  getLoads,
}: IAddLoadUserProps) => {
  const { currentCoachTeam } = useRootStore();
  const userStore = useContext(UserStoreContext);
  const { onDeckStudents } = userStore;
  const { data: jumpTypeData, loading: jumpTypeLoading } =
    useQuery(GET_ALL_JUMP_TYPES);
  const [selectedStudent, setSelectedStudent] = React.useState(null);

  const jumpTypes = useMemo(() => {
    if (jumpTypeData && !jumpTypeLoading) {
      return jumpTypeData.loadJumpTypes.map((jumpType) => ({
        label: jumpType.name,
        value: jumpType.id,
      }));
    }
    return [];
  }, [jumpTypeData, jumpTypeLoading]);
  const [addUserEvent] = useMutation(ADD_USER_EVENT);

  const [addLoadUser] = useMutation(ADD_LOAD_USER, {
    onCompleted: async () => {
      toast.success("Student added to load");
      if (getLoads) getLoads();
      await addUserEvent({
        variables: {
          userEvent: {
            status: "student loaded",
            user_event_type_id: EUserEventsId.user_loaded,
            location_id: currentCoachTeam.default_location_id,
            team_id: currentCoachTeam.id,
            student_id: selectedStudent,
          },
        },
      });
    },
    refetchQueries: ["LOAD_LIST_QUERIES", "GET_LOAD"],
  });
  const renderForm = ({
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
  }) => {
    return (
      <>
        <Grid.Row>
          <Grid.Col xs={12} sm={12} lg={6}>
            <Form.Group label={""}>
              <Select
                autoFocus
                name={"student_id"}
                placeholder="Search by name..."
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                getOptionLabel={(option) =>
                  `${option.studentDetails.first_name} ${option.studentDetails.last_name}`
                }
                getOptionValue={(option) => option.studentDetails.id}
                options={onDeckStudents.filter(
                  (ods) =>
                    !currentPassengers.find((i) => i === ods.studentDetails.id),
                )}
                onChange={(option) => {
                  setFieldValue("student_id", option.studentDetails.id);
                  setSelectedStudent(option.studentDetails.id);
                }}
                value={onDeckStudents.find(
                  (ods) => ods.studentDetails.id === values.student_id,
                )}
              />
              <span className="field-error text-danger">
                {errors.student_id && touched.student_id && errors.student_id}
              </span>
            </Form.Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} lg={6}>
            <Form.Group label={""}>
              <Select
                name={"association_type_id"}
                placeholder="Select role..."
                options={LoadAssocType}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                value={LoadAssocType.find(
                  (lat) => lat.value === values.association_type_id,
                )}
                onChange={(option) => {
                  setFieldValue("association_type_id", option.value);
                }}
              />
              <span className="field-error text-danger">
                {errors.association_type_id &&
                  touched.association_type_id &&
                  errors.association_type_id}
              </span>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xs={12} sm={12} lg={6}></Grid.Col>
          <Grid.Col xs={12} sm={12} lg={6}>
            <Form.Group label={""}>
              <Select
                name={"jump_type_id"}
                placeholder="Select jump type (optional)"
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                options={jumpTypes}
                value={jumpTypes.find((jt) => jt.value === values.jump_type_id)}
                onChange={(option) => {
                  setFieldValue("jump_type_id", option.value);
                }}
              />
              <span className="field-error text-danger">
                {errors.jump_type_id &&
                  touched.jump_type_id &&
                  errors.jump_type_id}
              </span>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Button.List align="right">
          <Button
            pill
            icon={"cloud"}
            color={"primary"}
            size={"sm"}
            className={"float-right"}
            onClick={handleSubmit}
          >
            BLUE SKIES
          </Button>
          <Button
            pill
            color={"white"}
            size={"sm"}
            className={"float-right"}
            onClick={toggleModal}
          >
            CANCEL
          </Button>
        </Button.List>
      </>
    );
  };
  return (
    <Formik
      validationSchema={addLoadStudentValidationSchema}
      enableReinitialize={true}
      initialValues={{
        student_id: "",
        association_type_id: 4,
        load_id: loadId,
        jump_type_id: undefined,
        team_id: currentCoachTeam.id,
      }}
      onSubmit={async (values) => {
        await addLoadUser({ variables: { loadAssociatedUser: values } });
        if (toggleModal) {
          toggleModal();
        }
      }}
    >
      {(formaData) => renderForm(formaData)}
    </Formik>
  );
};

export default AddLoadPassenger;
