import { useMutation } from '@apollo/client'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Token } from '@stripe/stripe-js'
import CurrencyInputField from 'components/CurrencyInputField'
import FormField from 'components/FormField'
import Modal from 'components/Modal'
import AccountBalance from 'components/User/AccountBalance'
import { manualPaymentOpts } from 'components/constants'
import { Formik, FormikValues } from 'formik'
import { CHARGE_STRIPE_V2 } from 'graphql/CHARGE_STRIPE_V2'
import { ADD_STUDENT_ACCOUNT_BALANCE } from 'graphql/ADD_STUDENT_ACCOUNT_BALANCE'
import { ADD_STUDENT_ACCOUNT_BALANCE as ADD_STUDENT_ACCOUNT_BALANCE_TYPE } from 'graphql/types/ADD_STUDENT_ACCOUNT_BALANCE'
import { GET_ACCOUNT_BALANCE } from 'graphql/GET_ACCOUNT_BALANCE'
import { GET_ACCOUNT_BALANCE as GET_ACCOUNT_BALANCE_TYPE } from 'graphql/types/GET_ACCOUNT_BALANCE'
import { GET_STUDENT_BY_ID } from 'graphql/GET_STUDENT_BY_ID'
import { GET_ACCOUNT_BALANCE_PAYMENT_HISTORY } from 'graphql/GET_ACCOUNT_BALANCE_PAYMENT_HISTORY'
import { useRootStore } from 'hooks'
import { EManualPaymentOpts } from 'modules/payment/constants/manualPaymentOptions'
import { UserStoreContext } from 'contexts/userStoreContext'
import React, { useContext, FC } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Form, Grid, Tab, TabbedCard, Text } from 'tabler-react'
import { convertToTime } from 'utils/convertToTime'
import { formatMoney } from 'utils/numberFormat'
import { parseIntNullable } from 'utils/numberParser'
import * as Yup from 'yup'
import { Types } from '../../../types/graphql'

const accountBalanceSchema = Yup.object().shape({
  currency: Yup.string().nullable(),
  email: Yup.string()
    .email('Invalid email.')
    .required('This field is required.'),
  isTime: Yup.boolean(),
  name: Yup.string().required('This field is required.'),
  productId: Yup.number().when('isTime', {
    is: true,
    then: Yup.number().required('This field is required.')
  }),
  productQty: Yup.number()
  //total: Yup.number()
  //.required('This field is required.')
  //.moreThan(0, 'Total should not be zero')
})

const manualFormSchema = Yup.object().shape({
  amount: Yup.number().required('This field is required.')
})

const qtyOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' }
]

type StripeAccountBalanceModalProps = {
  accountTypeSlug: string
  isModalOpen: boolean
  paymentFee: number
  products?: {
    amount: number
    value: number
    label: string
    quantity: string
    currency: string
  }[]
  stripeAccountId?: string
  studentAccountBalance: number
  user: Types.User
  team: Types.Team,
  teamName: string
  onCompleted: () => void
  toggleModal: () => void
  toggleStatusModal: () => void
}

const StripeAccountBalanceModal: FC<StripeAccountBalanceModalProps> = ({
  accountTypeSlug,
  isModalOpen,
  paymentFee,
  products,
  stripeAccountId,
  studentAccountBalance,
  user,
  team,
  teamName,
  onCompleted,
  toggleModal,
  toggleStatusModal
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const studentEmail = user.email
  const studentName = `${user.first_name} ${user.last_name}`
  const studentId = Number(user.id)

  const { currentCoachTeam, currentUser } = useRootStore()

  const isTeamAccountTypeTime = accountTypeSlug === 'time'

  const isStripeEnabled = stripe && elements && stripeAccountId

  const [chargeStripeV2] = useMutation(CHARGE_STRIPE_V2, {
    onError: (err) => {
      toast.error(err.message)
    }
  })

  const [addStudentAccountBalance] =
    useMutation<ADD_STUDENT_ACCOUNT_BALANCE_TYPE>(ADD_STUDENT_ACCOUNT_BALANCE)

  const teamId = Number(team.id)

  const BalanceLine = () => {
    return (
      <Grid.Row className="mb-4">
        <Grid.Col width={6}>
          <Form.Group className="mb-0" label={team.name} />
        </Grid.Col>
        <Grid.Col width={6}>
          <Grid.Row>
            <Grid.Col>Balance:</Grid.Col>
            <Grid.Col className="text-right">
              <AccountBalance
                accountBalance={studentAccountBalance}
                slug={accountTypeSlug}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    )
  }

  const EmailLine = () => {
    if (studentEmail) {
      return (
        <Grid.Row className="mb-4">
          <Grid.Col>
            <Form.Group className="mb-0" label="Email">
              <Form.Input
                disabled={true}
                value={studentEmail}
                readOnly={true}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      )
    } else {
      return (
        <div className="form-group">
          <FormField name="email" label="Email" type="email" />
        </div>
      )
    }
  }

  const ProductSelectLine = ({
    calc,
    values,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    productTotal
  }) => {
    if (products.length > 0) {
      return (
        <Grid.Row className="mb-4">
          <Grid.Col xs={12} sm={12} lg={6} className="mt-1">
            <Select
              name="productId"
              isMulti={false}
              options={products}
              onChange={(param) => {
                const newCalc = calculateProduct({productId: param.value})
                //const newProduct = products.find((a) => a.value === param.value)
                //if (newProduct) {
                  //const productQty = param.quantity * values.qty
                  setFieldValue('currency', newCalc.currency)
                  setFieldValue('productId', newCalc.productId)
                  setFieldTouched('productId', false, false)
                //}
              }}
              value={calc.product}
            />
            <span className="field-error text-danger">
              {errors.productId && touched.productId && errors.productId}
            </span>
          </Grid.Col>
          <Grid.Col xs={4} sm={4} md={4} lg={2} className="mt-1">
            <Select
              placeholder="Qty"
              name="qty"
              options={qtyOptions}
              onChange={(param) => {
                // Set the productQty multiplied by the qty
                //const productQty = Number(product.quantity) * values.qty
                setFieldValue('qty', param.value)
              }}
              value={
                values.qty &&
                qtyOptions.filter(
                  (productStatus) => productStatus.value === values.qty
                )
              }
            />
          </Grid.Col>
          <Grid.Col xs={4} sm={4} md={4} lg={4} className="mt-1">
            <CurrencyInputField
              name="total"
              value={formatMoney(calc.productTotal)}
              disabled={values.productId}
              setFieldValue={setFieldValue}
            />
          </Grid.Col>
        </Grid.Row>
      )
    } else {
      return (
        <Grid.Row className="mb-4">
          <Grid.Col width={6} />
          <Grid.Col width={6}>
            <CurrencyInputField
              name="manualAmount"
              value={formatMoney(values.total)}
              placeholder="$0.00"
              autoFocus={true}
              onChangeSideEffect={(value) => {
                setFieldValue('productQty', value)
              }}
              setFieldValue={setFieldValue}
            />
          </Grid.Col>
        </Grid.Row>
      )
    }
  }

  const ProductSelectTotalLine = ({ calc, values }) => {
    return (
      <Grid.Row className="mb-4">
        <Grid.Col width={6} />
        <Grid.Col width={6}>
          <Grid.Row>
            <Grid.Col />
            <Grid.Col className="text-right">
              <Text className="text-success">
                {calc.handleTime ? (
                  <span>+{convertToTime(calc.product.quantity*values.qty)}</span>
                ) : (
                  ' + ' + formatMoney(calc.productTotal)
                )}
              </Text>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    )
  }

  const FeesLine = ({ fee }) => {
    if (fee > 0) {
      return (
        <Grid.Row className="mb-4">
          <Grid.Col width={6}></Grid.Col>
          <Grid.Col width={6}>
            <Grid.Row>
              <Grid.Col>
                Fees:
                <span
                  title={`${(
                    paymentFee * 100
                  ).toFixed()}% includes all processing fees, taxes, etc.`}
                >
                  <i className="fe fe-help-circle ml-2 text-secondary" />
                </span>
              </Grid.Col>
              <Grid.Col className="text-right">
                <Text>{formatMoney(fee)}</Text>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      )
    } else {
      return <></>
    }
  }

  const NamedLine = ({ name, amount }) => {
    return (
      <Grid.Row className="mb-4">
        <Grid.Col width={6}></Grid.Col>
        <Grid.Col width={6}>
          <Grid.Row>
            <Grid.Col>{name}</Grid.Col>
            <Grid.Col className="text-right">
              <Text>{formatMoney(amount)}</Text>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    )
  }

  const FancyCardElement = () => {
    return (
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4'
              },
              lineHeight: '1.5rem'
            },
            invalid: {
              color: '#9e2146'
            }
          },
          classes: {
            base: 'form-group form-control'
          }
        }}
      />
    )
  }

  const StripeFooter = () => {
    return (
      <Text.Small>
        Powered by{' '}
        <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
          Stripe
        </a>
        .{' '}
        <a
          href="https://stripe.com/legal/end-users"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </a>
        .{' '}
        <a
          href="https://stripe.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </a>
        .
      </Text.Small>
    )
  }

  const calculateProduct = (values) => {
    let productTotal = 0
    let currency = team.default_currency
    let product
    let productId
    const handleTime = isTeamAccountTypeTime && products.length > 0

    if (products.length > 0) {
      product = products.find((a) => a.value === values.productId) || products[0]
      productTotal = product.amount * values.qty
      currency = product.currency
      productId = product.value
    } else {
      productTotal = values.manualAmount || 0
    }

    const subtotal = productTotal >= 0 ? productTotal : 0
    const fees = subtotal > 0 ? subtotal * paymentFee : 0
    const amountDue = subtotal > 0 ? subtotal + fees : 0
    return {
      handleTime,
      currency,
      product,
      productId,
      paymentFee,
      manualAmount: values.manualAmount || 0,
      productTotal,
      subtotal,
      qty: values.qty || 1,
      fees,
      amountDue
    }
  }

  const StripeForm = (props) => {
    const {
      errors,
      handleSubmit,
      isSubmitting,
      setFieldTouched,
      setFieldValue,
      touched,
      values
    } = props

    const calc = calculateProduct(values)
    if (!calc) {
      return <></>
    }

    return (
      <form onSubmit={handleSubmit} className="stripe-form">
        <fieldset disabled={isSubmitting}>
          <BalanceLine />
          <ProductSelectLine {...props} calc={calc} />
          <ProductSelectTotalLine
            values={values}
            calc={calc}
          />
          <NamedLine name="Subtotal" amount={calc.subtotal} />
          <FeesLine fee={calc.fees} />
          <NamedLine name="Amount Due" amount={calc.amountDue} />
          <EmailLine />
          <div className="form-group">
            <FormField name="name" label="Name on Card" type="text" />
          </div>
          <FancyCardElement />

          <button
            type="submit"
            disabled={!stripe || isSubmitting}
            className="btn btn-block btn-primary"
          >
            {isSubmitting
              ? 'Processing...'
              : `Pay ${formatMoney(calc.amountDue)}`}
          </button>
          <StripeFooter />
        </fieldset>
      </form>
    )
  }

  const renderManualForm = ({
    values,
    handleChange,
    handleSubmit
  }: FormikValues) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row>
        <Grid.Col sm={12} lg={6}>
          <Form.Group label="Payment Type">
            <Form.Select name="manual_payment" onChange={handleChange}>
              {manualPaymentOpts.map((paymentType) => (
                <option value={paymentType.value} key={paymentType.value}>
                  {paymentType.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Grid.Col>
        <Grid.Col sm={6} lg={3}>
          <FormField
            name="amount"
            label="Amount"
            placeholder="Amount"
            type="number"
            value={values.amount}
            onChange={handleChange}
          />
        </Grid.Col>
      </Grid.Row>
      {studentEmail ? (
        <Grid.Row className="mb-4">
          <Grid.Col>
            <FormField
              disabled={true}
              name="email"
              label="Email"
              placeholder="Email"
              type="string"
              value={studentEmail}
              onChange={handleChange}
            />
          </Grid.Col>
        </Grid.Row>
      ) : (
        <div className="form-group">
          <FormField
            disabled={true}
            name="email"
            label="Email"
            type="email"
            readOnly={true}
          />
        </div>
      )}

      <button className="btn btn-primary btn-block" type="submit">
        Receive Payment of {formatMoney(values.amount)}
      </button>
    </form>
  )

  const cardSubmit = async ({ calc, name, email }) => {
    const card = elements.getElement(CardElement)
    const result = await stripe.createToken(card, { name })
    if (result.error) {
      toast.error(result.error)
      return { error: result.error }
    }

    const description = `Payment: ${formatMoney(
      calc.amountDue
    )} - ${name} - ${email}`
    try {
      await chargeStripeV2({
        variables: {
          chargeParams: {
            source: result.token.id,
            total: calc.subtotal,
            fees: calc.fees,
            description,
            amount: calc.amountDue,
            currency: calc.currency,
            student_id: studentId,
            team_id: teamId,
            account_student_id: studentId
          }
        }
      })
      onCompleted()
    } catch (error: any) {
      toast.error(error)
      return { error }
    }
    return result
  }

  // On submit manual payment
  const onSubmitManualPayment = async (
    amount: number,
    email: string,
    manual_payment: string
  ) => {
    const parsedUserId = parseIntNullable(user.id)
    if (!parsedUserId) {
      return
    }

    await addStudentAccountBalance({
      variables: {
        payload: {
          account_type_id: currentCoachTeam.account_type_id,
          amount,
          currency: 'USD', //currentCoachTeam?.account_type_slug ?? accountBalance?.getAccountBalance?.account_type_slug,
          description: `${team.name} Account - ${user.first_name} ${user.last_name} ${user.id}`,
          manual_payment,
          processed_by: 'Manual Payment',
          receipt_email: email,
          student_id: parsedUserId,
          team_id: Number(team.id),
          user_id: currentUser.id
        }
      }
    })
    onCompleted()
  }

  const initialCalc = calculateProduct({})
  return (
    <Modal
      content={
        <TabbedCard initialTab="Credit Card">
          <Tab title="Credit Card">
            <Formik
              enableReinitialize={true}
              validationSchema={accountBalanceSchema}
              initialValues={{
                currency: initialCalc.currency,
                email: studentEmail,
                name: studentName,
                isTime: isTeamAccountTypeTime,
                //productQty: initialCalc.productQty,
                productId: initialCalc.productId,
                qty: initialCalc.qty,
                error: ''
              }}
              onSubmit={async (
                values,
                { resetForm, setSubmitting, setFieldValue }
              ) => {
                const { name, email } = values

                if (!isStripeEnabled) {
                  console.log('stripe not enabled')
                  return
                }

                const calc = calculateProduct(values)
                const result = await cardSubmit({ name, calc, email })
                if (result.error) {
                  toast.error(result.error.message)
                  //setFieldValue("error", result.error.message)
                  return
                }

                setSubmitting(false)
                resetForm()
                toggleModal()
              }}
            >
              {(formikData) => StripeForm(formikData)}
            </Formik>
          </Tab>
          {!isTeamAccountTypeTime && (
            <Tab title="Manual Payment">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  amount: 0,
                  manual_payment: EManualPaymentOpts.cash
                }}
                validationSchema={manualFormSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  const { amount, manual_payment } = values

                  try {
                    await onSubmitManualPayment(
                      amount,
                      studentEmail,
                      manual_payment
                    )
                  } catch (error) {
                    toast.error(error)
                  }

                  setSubmitting(false)
                  resetForm()
                  toggleModal()
                }}
              >
                {(formikData) => renderManualForm(formikData)}
              </Formik>
            </Tab>
          )}
        </TabbedCard>
      }
      open={isModalOpen}
      title={studentName}
      onClose={toggleModal}
    />
  )
}

export default StripeAccountBalanceModal
