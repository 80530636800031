import React, { useContext } from "react";
import { Form, Grid } from "tabler-react";

import { EventStoreContext } from "../../../contexts/eventStoreContext";
import useGetCurrentUserType from "../../../modules/common/hooks/useGetCurrentUserType";

const EventTypesForm = ({ eventTypes, handleNext, mstHandleChange }) => {
  const { eventEntry } = useContext(EventStoreContext);
  const { isOrg } = useGetCurrentUserType();

  return (
    <Grid.Row>
      <Grid.Col>
        <Form.Group label="What type of event is this?" className="text-center">
          {eventTypes.map((eventType) => {
            if (eventType.id === 2 && !isOrg) {
              return <div key={`${eventType.id}-${eventType.name}`}></div>;
            }

            return (
              <div
                className="switchradio position-relative"
                key={`${eventType.id}-${eventType.name}`}
              >
                <input
                  type="radio"
                  value={eventType.id}
                  id={`${eventType.id}-${eventType.name}`}
                  name="event_type_id"
                  className="switchradio-input position-absolute"
                  onChange={(e) => {
                    mstHandleChange(e);
                    handleNext();
                  }}
                  required={true}
                  checked={eventEntry.event_type_id === eventType.id}
                />
                <label
                  htmlFor={`${eventType.id}-${eventType.name}`}
                  className="btn btn-secondary d-block"
                >
                  {eventType.name}
                </label>
              </div>
            );
          })}
        </Form.Group>
      </Grid.Col>
    </Grid.Row>
  );
};

export default EventTypesForm;
