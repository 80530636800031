import { observer } from "mobx-react";
import MyEventsCard from "modules/camp/components/dashboard/MyEventsCard";
import FeatureAccessGate from "modules/common/components/FeatureAccessGate";
import StudentsOnDashboard from "modules/student/components/dashboard/StudentsOnDashboard";
import React from "react";
import { Button, Card, Grid, Icon } from "tabler-react";
import { useRootStore } from "../../hooks";
import useIsFeatureAccessible from "../../modules/common/hooks/useIsFeatureAccessible";
import GroupFormModal from "../Calendar/Modal/GroupFormModal";
import DashboardGroups from "../Coaches/DashboardGroups";
import TeamPublicDetails from "../Team/TeamPublicDetails";
import { EUserTypes } from "../User/userTypes";

const CoachDashboard = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { currentUser, currentCoachTeam } = useRootStore();

  const { isFeatureAccessible: hasDashboard } = useIsFeatureAccessible({
    currentCoachTeam,
    currentUserType: currentUser?.type as EUserTypes,
    feature: "feature_dashboard",
  });

  return (
    <>
      {!hasDashboard && currentCoachTeam ? (
        <TeamPublicDetails />
      ) : (
        <Card>
          <Card.Header>
            <Card.Title>
              <Icon name="home" className="mr-2 ml-0 text-primary" />
              Dashboard
            </Card.Title>
            <Card.Options>
              <Button.List align="right">
                <FeatureAccessGate feature="feature_dashboard_groups">
                  <Button
                    icon="plus"
                    color="primary"
                    size="sm"
                    onClick={() => setIsModalOpen(true)}
                  >
                    GROUP
                  </Button>
                </FeatureAccessGate>
              </Button.List>
            </Card.Options>
          </Card.Header>
        </Card>
      )}
      <Grid.Row>
        <Grid.Col width={12}>
          <StudentsOnDashboard missingInfo={true} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <FeatureAccessGate feature="feature_dashboard_groups">
          <Grid.Col width={12}>
            <DashboardGroups />
          </Grid.Col>
        </FeatureAccessGate>
        <GroupFormModal
          isOpen={isModalOpen}
          toggle={setIsModalOpen}
          teamId={currentCoachTeam?.id.toString()}
        />
      </Grid.Row>
      <MyEventsCard />
      <FeatureAccessGate feature="feature_dashboard">
        <Grid.Col className="pl-0 pr-0">
          <StudentsOnDashboard missingInfo={false} />
        </Grid.Col>
      </FeatureAccessGate>
    </>
  );
};

export default observer(CoachDashboard);
