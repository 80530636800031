import gql from "graphql-tag";

export const GET_PAYMENTS = gql`
  query GET_PAYMENTS($params: GetPaymentsParams) {
    getPayments(params: $params) {
      payments {
        id
        amount
        application_fee
        camp_name
        card_brand
        created_on
        currency
        fees
        first_name
        invoice_id
        last_name
        last4
        manual_payment
        processed_by
        product_title
        status
        team_name
        total
      }

      total
      totalComp
      totalCompCount
      totalPaid
      totalPaidCount
    }
  }
`;
