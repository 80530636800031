import { useQuery } from '@apollo/client'
import { GET_CAMP_REGISTRATION_REQUIREMENTS } from 'graphql/GET_CAMP_REGISTRATION_REQUIREMENTS'
import { GET_CAMP_REGISTRATION_REQUIREMENTS as GET_CAMP_REGISTRATION_REQUIREMENTS_TYPE } from 'graphql/types/GET_CAMP_REGISTRATION_REQUIREMENTS'
import React, { useMemo } from 'react'
import { Grid } from 'tabler-react'
import { Types } from '../../../../../types/graphql'

type CampRequirementsProps = {
  userRegistrationDetails: Types.UserRegistrationsById
}

const CampRequirements = ({
  userRegistrationDetails
}: CampRequirementsProps) => {
  const { data: campRequirementsData } =
    useQuery<GET_CAMP_REGISTRATION_REQUIREMENTS_TYPE>(
      GET_CAMP_REGISTRATION_REQUIREMENTS,
      {
        variables: {
          team_id: userRegistrationDetails.team_id,
          camp_id: Number(userRegistrationDetails.camp_id)
        }
      }
    )

  const campRequirements = useMemo(() => {
    return (
      campRequirementsData?.campRegistrationRequirements
        .filter((r) => r.is_exist)
        .map((r) => r.requirement_type_slug) ?? []
    )
  }, [campRequirementsData?.campRegistrationRequirements])  

  return (
    <Grid.Row>
      {campRequirements.includes('location') && (
        <Grid.Col width="12">
          <b>Location:</b> {userRegistrationDetails.location}
        </Grid.Col>
      )}
      {campRequirements.includes('role') && (
        <Grid.Col width="12">
          <b>Role:</b> {userRegistrationDetails.role}
        </Grid.Col>
      )}
      {campRequirements.includes('discipline') && userRegistrationDetails.discipline && (
        <Grid.Col width="12">
          <b>Primary Discipline:</b> {userRegistrationDetails.discipline}
        </Grid.Col>
      )}
    </Grid.Row>
  )
}

export default CampRequirements
