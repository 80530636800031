import { useQuery } from "@apollo/client";
import { DatePickerView } from "@material-ui/pickers";
import { observer } from "mobx-react";
import { applySnapshot } from "mobx-state-tree";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Alert, Button, Card, Dimmer, Grid, Icon, Table } from "tabler-react";
import useGetCurrentUserType from "../../modules/common/hooks/useGetCurrentUserType";
import { TransactionContext } from "../../contexts/TransactionContext";
import { GET_TRANSACTIONS } from "../../graphql/GET_TRANSACTIONS";
import { useRootStore } from "../../hooks";
import DatePicker from "../DatePicker";
import TransactionListItem from "./TransactionListItem";

export const DEFAULT_LIMIT: number = 30;

const DateFilter = ({ dateFilter, setDateFilter, filterByDay }) => {
  const dateUnit = filterByDay ? "days" : "months";
  const dateView = filterByDay ? ["date"] : ["month"];
  const dateFormat = filterByDay ? "dd MMMM yyyy" : "MMMM yyyy";
  return (
    <>
      <Button
        className="text-secondary"
        color="white"
        icon="chevron-left"
        size="sm"
        onClick={() => {
          setDateFilter(moment(dateFilter).subtract(1, dateUnit).toDate());
        }}
      />
      <DatePicker
        name="created_on"
        placeholder="Select Date"
        onChange={(date) => setDateFilter(date)}
        value={dateFilter}
        views={dateView as DatePickerView[]}
        format={dateFormat}
        className="w-75"
        maxDate={new Date()}
      />
      <Button
        className="text-secondary"
        color="white"
        icon="chevron-right"
        size="sm"
        disabled={
          filterByDay
            ? moment(dateFilter).isSame(new Date(), "month") &&
              moment(dateFilter).isSame(new Date(), "year") &&
              moment(dateFilter).isSame(new Date(), "day")
            : moment(dateFilter).isSame(new Date(), "month") &&
              moment(dateFilter).isSame(new Date(), "year")
        }
        onClick={() => {
          setDateFilter(moment(dateFilter).add(1, dateUnit).toDate());
        }}
      />
    </>
  );
};

const TransactionList = () => {
  const [page, setPage] = useState<number>(2);
  const { transactions } = useContext(TransactionContext);
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType();
  const { currentCoachTeam } = useRootStore();
  const filter = { teamId: currentCoachTeam?.id };
  const [dateFilter, setDateFilter] = useState(new Date());
  const [filterByDay, setFilterByDay] = useState(false);
  const { loading, error, data, fetchMore } = useQuery(GET_TRANSACTIONS, {
    variables: {
      limit: DEFAULT_LIMIT,
      page: 1,
      filter: { ...filter, dateFilter, filterByDay },
    },
  });

  useEffect(() => {
    if (!loading && data) {
      applySnapshot(transactions, data.getTransactions.transactions);
    }
  }, [data]);

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  const onFetchMore = () => {
    setPage((prevNum) => prevNum + 1);
    return fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }

        return {
          ...cache,
          getTransactions: {
            ...cache.getTransactions,
            transactions: [
              ...cache.getTransactions.transactions,
              ...fetchMoreResult.getTransactions.transactions,
            ],
          },
        };
      },
      variables: {
        limit: DEFAULT_LIMIT,
        page: page,
        filter: { ...filter, dateFilter, filterByDay },
      },
    });
  };

  if (!isAdmin && !currentCoachTeam) {
    return (
      <Alert type="warning">You must have a team to view transactions</Alert>
    );
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <Icon name="list" className="mr-2 ml-0 text-primary" />
          Transactions
        </Card.Title>
        <Card.Options>
          {isAdmin || isCoachSuperAdmin && (
          <Button
            color={"pink"}
            onClick={() => setFilterByDay(!filterByDay)}
            className={"mr-1"}
          >
            {filterByDay ? "Day" : "Month"}
          </Button>
          )}
          <DateFilter
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            filterByDay={filterByDay}
          />
          {(isAdmin || isCoachSuperAdmin) && (
          <Button
            outline
            icon="download"
            color="secondary"
            className={"ml-2"}
            disabled
          >
            CSV
          </Button>
          )}
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
          <Grid.Col>
            <Dimmer active={loading} loader={loading}>
              <Grid.Col width={12}>
                <InfiniteScroll
                  className="overflow-visible pb-3"
                  dataLength={transactions.length}
                  hasMore={
                    transactions.length <
                    (data?.getTransactions.totalCount || 0)
                  }
                  loader={<Dimmer active={true} loader={true} />}
                  next={onFetchMore}
                  scrollThreshold="210px"
                  endMessage={
                    <Alert className="text-center" type="primary">
                      No {transactions.length !== 0 && "more"} transactions
                    </Alert>
                  }
                >
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        {isAdmin && <Table.ColHeader>ID</Table.ColHeader>}
                        <Table.ColHeader>USER</Table.ColHeader>
                        <Table.ColHeader>TYPE</Table.ColHeader>
                        <Table.ColHeader>TIMESTAMP</Table.ColHeader>
                        {isAdmin && <Table.ColHeader>TEAM</Table.ColHeader>}
                        <Table.ColHeader>COACH</Table.ColHeader>
                        <Table.ColHeader>AMOUNT</Table.ColHeader>
                        <Table.ColHeader>NOTES</Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {transactions.map((transaction) => (
                        <Table.Row key={transaction.id}>
                          <TransactionListItem transaction={transaction} />
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </InfiniteScroll>
              </Grid.Col>
            </Dimmer>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  );
};

export default observer(TransactionList);
