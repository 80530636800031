import React from "react";
import Modal from "../../../../components/Modal";
import AccountBalanceForm, {
  TransactionType,
} from "../../forms/AccountBalanceForm";

interface AccountBalanceModalProps {
  isOpen: boolean;
  toggleModal: (value: boolean) => void;
  transactionType: keyof typeof TransactionType;
  student: any;
  accountTypeId: number;
  accountTypeSlug: string;
  studentBalance: any;
}

const AccountBalanceModal = ({
  isOpen,
  toggleModal,
  accountTypeId,
  accountTypeSlug,
  transactionType,
  studentBalance,
  student,
}: AccountBalanceModalProps) => {
  return (
    <Modal
      content={
        <AccountBalanceForm
          accountTypeId={accountTypeId}
          accountTypeSlug={accountTypeSlug}
          defaultTransactionType={transactionType}
          studentBalance={studentBalance}
          studentName={`${student.first_name} ${student.last_name}`}
          handleSetAccountBalanceFormShown={toggleModal}
          isRegistered={!!student.registration_id}
        />
      }
      open={isOpen}
      onClose={() => toggleModal(false)}
      title={`${student.first_name} ${student.last_name}`}
    />
  );
};

export default AccountBalanceModal;
