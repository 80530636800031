import { useLazyQuery, useQuery } from "@apollo/client";
import TeamAccessFeature from "components/Team/Form/TeamAccessFeature";
import TeamPermissionFeature from "components/Team/Form/TeamPermissionFeature";
import TeamRegistrationSettings from "components/Team/Form/TeamRegistrationSettings";
import { observer } from "mobx-react";
import { applySnapshot, clone } from "mobx-state-tree";
import FeatureAccessGate from "modules/common/components/FeatureAccessGate";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import CoachAccountType from "modules/team/components/buttons/CoachAccountType";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Alert,
  Button,
  Card,
  Dimmer,
  Form,
  Grid,
  Icon,
  Text,
} from "tabler-react";
import useReactRouter from "use-react-router";

import { EUserTypes } from "../../../components/User/userTypes";
import { TeamStoreContext } from "../../../contexts/teamStoreContext";
import { GET_REGISTRATION_OPTIONS } from "../../../graphql/GET_REGISTRATION_OPTIONS";
import { GET_TEAMS } from "../../../graphql/GET_TEAMS";
import { useRootStore } from "../../../hooks/useRootStore";
import { Types } from "../../../types/graphql";
import { formatMoney } from "../../../utils/numberFormat";
import CompensationTypeList from "../../Compensation/CompensationTypeList";
import LoadPaymentTypeList from "../../LoadPayment/LoadPaymentTypeList";
import TeamImages from "../Image/TeamImages";
import RegistrationOptionsModal from "../Modals/RegistrationOptionsModal";
import TeamActivitiesList from "../TeamActivitiesList";
import { ECampFeeType, ERegistrationOptionType } from "../TeamList";
import TeamProgressions from "../TeamProgressions";
import TeamRegistrationQuestions from "../TeamRegistrationQuestions";

import RegistrationStatus from "./RegistrationStatus";
import TeamProfile from "./TeamProfile";
import TeamRegistrationRequirement from "./TeamRegistrationRequirement";

enum EModalTypes {
  registrationsOptions = "registrationsOptions",
  registrationsQuestions = "registrationsQuestions",
}

interface IModalStates {
  registrationsOptions: boolean;
  registrationsQuestions: boolean;
}

const modalInitialStates = {
  registrationsOptions: false,
  registrationsQuestions: false,
};
export const noData: JSX.Element = (
  <Alert type="info">
    <strong>No Data</strong>
  </Alert>
);
const TeamEdit = () => {
  const teamStore = useContext(TeamStoreContext);
  const { fee_types, registration_option_types, teams } = teamStore;
  const { isAdmin, isCoachAdmin, isCoachSuperAdmin, isDzm, isWtm } =
    useGetCurrentUserType();
  const { teamId } = useParams<{ teamId: string }>();

  const { history } = useReactRouter();
  const { currentUser, currentCoachTeam } = useRootStore();

  const [isModalOpen, setIsModalOpen] =
    useState<IModalStates>(modalInitialStates);
  const [optionsByType, setOptionsByType] = useState([]);
  const [registrationOption, setRegistrationOption] = useState<any>();

  const team = teamStore.getTeamById(teamId);

  const { loading, error, data } = useQuery(GET_REGISTRATION_OPTIONS, {
    variables: {
      teamId,
    },
  });

  const [
    getTeams,
    { loading: teamsLoading, error: teamsError, data: teamsData },
  ] = useLazyQuery(GET_TEAMS, {
    variables: {
      filter: {},
    },
  });

  useEffect(() => {
    if (
      currentUser.type !== EUserTypes.admin &&
      Number(teamId) !== currentCoachTeam?.id
    ) {
      history.push(`/team-details/${currentCoachTeam?.id}`);
    }
  }, [currentCoachTeam?.id]); // Eslint-disable-line

  useEffect(() => {
    if (!loading && !error && data) {
      const opts = [];

      data.getRegistrationOptions.forEach(
        (regOpt: Types.RegistrationOption) => {
          if (!opts[regOpt.fee_type_name]) {
            opts[regOpt.fee_type_name] = [];
          }

          opts[regOpt.fee_type_name].push(regOpt);
        },
      );

      Object.entries(opts).forEach(([key, value]) => {
        const registrationOptionsListItem = {
          key: undefined,
          value: undefined,
        };
        registrationOptionsListItem.key = key;
        registrationOptionsListItem.value = value;
        opts.push(registrationOptionsListItem);
      });

      setOptionsByType(opts);
    }
  }, [data]); // Eslint-disable-line

  useEffect(() => {
    if (!team) {
      getTeams();
    }

    if (!teamsLoading && !teamsError && teamsData) {
      applySnapshot(
        fee_types,
        teamsData.feeTypes.filter(
          (feeType) =>
            feeType.name !== ECampFeeType.per_slot &&
            feeType.name !== ECampFeeType.per_day,
        ),
      );
      applySnapshot(
        registration_option_types,
        teamsData.getRegistrationOptionTypes.filter(
          (regOptType: Types.RegistrationOptionTypes) =>
            regOptType.name !== ERegistrationOptionType.payment,
        ),
      );
      applySnapshot(teams, teamsData.teams);
    }
  }, [teamsData]); // Eslint-disable-line

  const handleToggleModal = (mType: EModalTypes) =>
    setIsModalOpen({
      ...isModalOpen,
      [mType]: !isModalOpen[mType],
    });

  /* I don't know why this is here: <Alert type="danger">{`Team with id: ${teamId} not found`}</Alert> */
  if (!team) {
    return <Alert type="danger">{`Team with id: ${teamId} not found`}</Alert>;
  }

  const teamClone = clone(team);

  return (
    <>
      <Grid.Row>
        <Grid.Col lg={8} sm={12} xs={12} className="grid-col">
          <Card statusColor="blue">
            <Card.Header className="pl-3">
              <Card.Title>
                <Icon
                  name="check-circle"
                  className={`mr-2 ml-0 ${
                    team.is_published ? "text-success" : "text-secondary"
                  }`}
                />
                {team.name || "ID Not Found"}
              </Card.Title>
              <Card.Options>
                {isAdmin && (
                  <Button
                    color="white"
                    size="sm"
                    className="mr-2 text-primary"
                    RootComponent="a"
                    href={`/staff?team_id=${teamId}`}
                  >
                    Staff Page
                  </Button>
                )}
                <CoachAccountType teamId={Number(teamId)} />
              </Card.Options>
            </Card.Header>
          </Card>
          <Card>
            <TeamProfile team={team} teamClone={teamClone} />
          </Card>

          {isAdmin && <TeamActivitiesList />}
          <FeatureAccessGate feature="feature_student_progression">
            <TeamProgressions teamId={teamId} />
          </FeatureAccessGate>
          {/* Registration Questions Card */}
          <FeatureAccessGate feature="feature_registrations">
            <TeamRegistrationQuestions teamId={teamId} team={team} />
          </FeatureAccessGate>
        </Grid.Col>
        <Grid.Col lg={4} sm={12} xs={12} className="grid-col">
          <Card statusColor="blue">
            <Card.Header>
              <Card.Title>Images</Card.Title>
            </Card.Header>
            <Card.Body>
              <TeamImages team={team} teamClone={teamClone} />
            </Card.Body>
          </Card>
          {isAdmin && (
            <>
              <Card statusColor="blue">
                <Card.Header>
                  <Card.Title>Access</Card.Title>
                </Card.Header>
                <Card.Body className="pb-0">
                  <TeamAccessFeature team={team} teamClone={teamClone} />
                </Card.Body>
              </Card>

              <Card statusColor="blue">
                <Card.Header>
                  <Card.Title>Permissions</Card.Title>
                </Card.Header>
                <Card.Body className="pb-0">
                  <TeamPermissionFeature team={team} teamClone={teamClone} />
                </Card.Body>
              </Card>

              <Card statusColor="blue">
                <Card.Header>
                  <Card.Title>Registration Settings</Card.Title>
                </Card.Header>
                <Card.Body className="pb-0">
                  <TeamRegistrationSettings team={team} teamClone={teamClone} />
                </Card.Body>
              </Card>

              {isAdmin && (
                <Card statusColor="blue">
                  <Card.Header>
                    <Card.Title>Registration Statuses</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <RegistrationStatus team={team} />
                  </Card.Body>
                </Card>
              )}

              <Card statusColor="success">
                <Card.Header>
                  <Card.Title>
                    <Icon
                      name="credit-card"
                      className=" text-success mr-2 ml-0"
                    />
                    Payments
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form.Group label="Customer Payment Fee">
                    <Form.InputGroup>
                      <Form.Input
                        name="payment_fee_customer"
                        placeholder="0.00"
                        disabled
                        value={team.payment_fee_customer.toFixed(2)}
                      />
                      <Form.InputGroupAppend>
                        <Button color="secondary" disabled>
                          %
                        </Button>
                      </Form.InputGroupAppend>
                    </Form.InputGroup>
                    <Text.Small className="pt-3 text-muted">
                      The fee charged to customers
                    </Text.Small>
                  </Form.Group>
                  <Grid.Row>
                    <Grid.Col lg={6} sm={12} xs={12}>
                      <Form.Group label="Payment Fee Flat">
                        <Form.InputGroup>
                          <Form.InputGroupAppend>
                            <Button color="secondary" disabled>
                              $
                            </Button>
                          </Form.InputGroupAppend>
                          <Form.Input
                            name="payment_fee_flat"
                            placeholder="0.00"
                            disabled
                            value={team.payment_fee_flat.toFixed(2)}
                          />
                        </Form.InputGroup>
                        <Text.Small className="pt-3 text-muted">
                          Captured Fees
                        </Text.Small>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col lg={6} sm={12} xs={12}>
                      <Form.Group label="Payment Fee %">
                        <Form.InputGroup>
                          <Form.Input
                            name="payment_fee_percentage"
                            placeholder="0.00"
                            disabled
                            value={team.payment_fee_percentage.toFixed(2)}
                          />
                          <Form.InputGroupAppend>
                            <Button color="secondary" disabled>
                              %
                            </Button>
                          </Form.InputGroupAppend>
                        </Form.InputGroup>
                        <Text.Small className="pt-3 text-muted">
                          Captured Fees
                        </Text.Small>
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>

                  <Form.Group label="Stripe Acct ID">
                    <Form.Input
                      name="stripe_account_id"
                      placeholder="acct_"
                      disabled
                      value={team.stripe_account_id}
                    />
                  </Form.Group>
                  <Form.Group label="Domain">
                    <Form.InputGroup>
                      <Form.Input
                        name="slug"
                        placeholder="team.slug"
                        disabled
                        value={team.slug}
                      />
                      <Form.InputGroupAppend>
                        <Button color="secondary" disabled>
                          .skycru.com
                        </Button>
                      </Form.InputGroupAppend>
                    </Form.InputGroup>
                  </Form.Group>
                </Card.Body>
              </Card>
            </>
          )}
          {team.slug && (
            <Card>
              <Card.Body>
                {(isCoachAdmin || isCoachSuperAdmin) && (
                  <FeatureAccessGate feature="feature_payments">
                    {team.stripe_account_id ? (
                      <Form.Group label="Stripe Account ID:">
                        <Form.Input
                          name="stripe_account_id"
                          placeholder="acct_"
                          disabled
                          value={team.stripe_account_id}
                        />
                      </Form.Group>
                    ) : (
                      <Button
                        block
                        outline
                        icon="alert-circle"
                        color="danger"
                        className="mb-5"
                        disabled
                      >
                        Payments Not Configured
                      </Button>
                    )}
                  </FeatureAccessGate>
                )}
                <Form.Group label="Team Page URL:">
                  <Text>
                    <a
                      href={"https://portal.skycru.com/" + team.slug}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {"https://portal.skycru.com/" + team.slug}
                    </a>
                  </Text>
                </Form.Group>
                <Form.Group label="Email Forward:">
                  <a
                    href={"mailto:" + team.slug + "@skycru.com"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {team.slug + "@skycru.com"}
                  </a>
                  <Text.Small className="d-block text-muted">
                    Forwards to {team.team_email}.
                  </Text.Small>
                </Form.Group>
              </Card.Body>
            </Card>
          )}
          <FeatureAccessGate feature="feature_registrations">
            <Card>
              <Card.Header>
                <Card.Title>
                  Registration Options
                </Card.Title>
                <Card.Options>
                  <Button
                    outline
                    icon="plus"
                    color="primary"
                    size="sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleToggleModal(EModalTypes.registrationsOptions);
                    }}
                  />
                  </Card.Options>
              </Card.Header>
              <Card.Body>
                <Dimmer active={loading} loader={loading}>
                  {!optionsByType.length
                    ? noData
                    : optionsByType.map((regOpt) => (
                        <React.Fragment key={regOpt.key}>
                          <p className="mb-0 text-primary text-uppercase">
                            <strong>{regOpt.key}</strong>
                          </p>
                          <hr className="my-0" />
                          {regOpt.value.map((opt: Types.RegistrationOption) => (
                            <div
                              className="cursor-pointer"
                              key={opt.id}
                              onClick={(e) => {
                                e.preventDefault();

                                setRegistrationOption(opt);

                                handleToggleModal(
                                  EModalTypes.registrationsOptions,
                                );
                              }}
                            >
                              <p className="mb-0">
                                <strong>{opt.name}</strong>
                              </p>
                              <p>
                                {formatMoney(Number(opt.option_value))}{" "}
                                {opt.fee_type_name}
                              </p>
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                </Dimmer>
              </Card.Body>
            </Card>
            {isAdmin && <TeamRegistrationRequirement team_id={teamId} />}
          </FeatureAccessGate>
          {isDzm && (
            <FeatureAccessGate feature="feature_alpha">
              <Card statusColor="blue">
                <Card.Header>
                  <Card.Title>DZ Info</Card.Title>
                  <Card.Options></Card.Options>
                </Card.Header>
                <Card.Body>
                  <Alert type="info" className="text-center">
                    <strong>No Location</strong>
                  </Alert>
                </Card.Body>
              </Card>
              <CompensationTypeList teamId={Number(teamId)} />
              <LoadPaymentTypeList />
            </FeatureAccessGate>
          )}
        </Grid.Col>
      </Grid.Row>
      <RegistrationOptionsModal
        isModalOpen={isModalOpen.registrationsOptions}
        handleToggleModal={() =>
          handleToggleModal(EModalTypes.registrationsOptions)
        }
        registrationOption={registrationOption}
        setRegistrationOption={setRegistrationOption}
        team={team}
      />
    </>
  );
};

export default observer(TeamEdit);
